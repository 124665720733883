import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { CareerIconPropType, StrapiImageSharpPropType } from '../propTypes'
import FormWrapper from '../FormWrapper/FormWrapper'
import ApplicationFormLeftSide from './ApplicationFormLeftSide'
import JotformEmbed from 'react-jotform-embed'

const ApplicationForm = ({ header, icons, image }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <SectionWrapper className='application-form-container'>
    <div className='application-form-container__header'>
      {header}
    </div>
    <div className='application-form-container__mobile-image'>
      <GatsbyImage
        alt={image?.alternativeText}
        image={gatsbyImageData}
      />
    </div>
    <FormWrapper
      leftSide={<ApplicationFormLeftSide icons={icons} />}
      rightSide={<JotformEmbed src='https://form.jotform.com/220446770581356' />}
    />
  </SectionWrapper>
}

export default ApplicationForm

ApplicationForm.propTypes = {
  header: PropTypes.string,
  icons: PropTypes.arrayOf(CareerIconPropType),
  image: StrapiImageSharpPropType,
}

ApplicationForm.defaultProps = {
  header: '',
  icons: [],
  image: {}
}
