import React from 'react'
import Layout from '../components/Layout/Layout'
import { graphql } from 'gatsby'
import ApplicationForm from '../components/ApplicationForm/ApplicationForm'

const ApplicationFormPage = ({ data }) =>
  <Layout description='Bewerbungsformular'>
    <ApplicationForm {...data?.strapiApplicationForm} />
  </Layout>

export default ApplicationFormPage

export const query = graphql`
  query ApplicationFormPageQuery {
  ...applicationFormFragment
  }
 `
