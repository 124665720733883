import React from 'react'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'

const ApplicationFormIcon = ({ header, image, index }) =>
  <div className='application-form-icon'>
    <div className='application-form-icon__image'>
      <div className='application-form-icon__number'>{index + 1}.</div>
      <img
        src={image.url}
        alt={image.alternativeText}
      />
    </div>
    <div className='application-form-icon__header'>{header}</div>
  </div>

export default ApplicationFormIcon

ApplicationFormIcon.propTypes = {
  header: PropTypes.string,
  image: StrapiImagePropType,
  index: PropTypes.number
}

ApplicationFormIcon.defaultProps = {
  header: '',
  image: {},
  index: 0
}
