import React from 'react'
import ApplicationFormIcon from './ApplicationFormIcon'
import PropTypes from 'prop-types'
import { CareerIconPropType } from '../propTypes'

const ApplicationFormLeftSide = ({ icons }) =>
  <>
    <p className='application-form-left-side-header'>
      <span>In nur </span>
      <span>4</span>
      <span> Schritten Mitarbeiter bei SenPrima werden!</span>
    </p>
    {icons?.map((icon, index) =>
      <ApplicationFormIcon
        key={index}
        {...icon}
        index={index}
      />
    )}
  </>

export default ApplicationFormLeftSide

ApplicationFormLeftSide.propTypes = {
  icons: PropTypes.arrayOf(CareerIconPropType),
}

ApplicationFormLeftSide.defaultProps = {
  icons: [],
}
